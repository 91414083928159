.list-item-res-drawer:hover {
  background-color: #1860aa !important;
  color: #fff !important;
}

.list-item-icon-nav:hover {
  color: #fff !important;
}

.main-box-res-drawer header {
  box-shadow: unset !important;
  padding-right: unset !important;
}

.main-box-res-drawer header .appbar-res-drawer {
  display: flex;
  justify-content: space-between;
  background: white;
}

@media screen and (max-width: 600px) {
  .main-box-res-drawer header .appbar-res-drawer {
    display: flex;
    justify-content: space-between !important;
  }
  .show-arrow-back {
    display: none !important;
  }
}

.list-item-icon-nav {
  min-width: 35px !important;
  color: unset !important;
}
